<template>
  <div style="height: 85%">
    <section class="justify-center align-center">
      <v-col class="image__title d-flex justify-center align-start pt-8">
        <img
          width="10%"
          src="../../assets/conteudos_casas_home_title.png"
          alt=""
        />
      </v-col>
    </section>
    <section
      class="container d-flex flex-column justify-start"
      style="height: 100%"
    >
      <v-row class="ballon__row">
        <v-col
          class="img__links d-flex justify-center align-center flex-column"
        >
          <img
            @click="goToPage('conteudos_tematicos_casas_ocupacao_territorio')"
            src="../../assets/conteudos_casas_home_01.png"
            alt=""
          />
        </v-col>
        <v-col
          class="img__links d-flex justify-center align-center flex-column"
        >
          <img
            @click="goToPage('conteudos_tematicos_casas_espaco_moradia')"
            src="../../assets/conteudos_casas_home_02.png"
            alt=""
          />
        </v-col>
        <v-col
          class="img__links d-flex justify-center align-center flex-column"
        >
          <img
            @click="goToPage('conteudos_tematicos_casas_jardins_quintais')"
            src="../../assets/conteudos_casas_home_03.png"
            alt=""
          />
        </v-col>
        <v-col
          class="img__links d-flex justify-center align-center flex-column"
        >
          <img
            @click="goToPage('conteudos_tematicos_casas_deslocamento_forcado')"
            src="../../assets/conteudos_casas_home_07.png"
            alt=""
          />
        </v-col>
      </v-row>

      <v-row class="ballon__row">
        <v-col
          class="img__links d-flex justify-center align-center flex-column"
        >
          <img
            @click="goToPage('conteudos_tematicos_casas_desterritorializacao')"
            src="../../assets/conteudos_casas_home_04.png"
            alt=""
          />
        </v-col>
        <v-col
          class="img__links d-flex justify-center align-center flex-column"
        >
          <img
            @click="goToPage('conteudos_tematicos_casas_vida_provisoria')"
            src="../../assets/conteudos_casas_home_05.png"
            alt=""
          />
        </v-col>
        <v-col
          class="img__links d-flex justify-center align-center flex-column"
        >
          <img
            @click="goToPage('conteudos_tematicos_casas_tecnicas_construtivas')"
            src="../../assets/conteudos_casas_home_06.png"
            alt=""
          />
        </v-col>

        <v-col
          class="img__links d-flex justify-center align-center flex-column"
        >
          <img
            @click="goToPage('conteudos_tematicos_casas_vias_acesso')"
            src="../../assets/conteudos_casas_home_08.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    goToPage(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>

<style scoped>
.img__links img {
  width: 80%;
  cursor: pointer;
}

@media (max-width: 600px) {
  .img__links img {
    width: 70%;
  }

  .image__title img {
    width: 30%;
  }

  .ballon__row {
    flex-direction: column;
  }
}

.ballon__row {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
